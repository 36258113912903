import React from 'react'
import { graphql } from 'gatsby'
import { useMenu } from '../data/hooks/menu'
import { formatImage } from './ImageWrapper'
import { mapperWithFunction } from '../common/utils'
import { Link } from 'gatsby'
import { Badges, Heading } from '@roar/components'
import { theme } from '../common/theme'
import { Box } from 'rebass/styled-components'
import { mapper } from '../common/utils'

const BADGES_PROPS_MAP = {
  headline: ['headline'],
  menuName: ['badges', 'locations'],
  style: ['style'],
}
const BADGES_MAP = {
  name: ['name'],
  links: ['links'],
}

const BadgesWrapper = (props) => {
  const { headline, menuName, style, ...rest } = mapper(props, BADGES_PROPS_MAP)
  const { items: badges = [] } = useMenu(menuName[0])

  return (
    <Box className="BadgesWrapper" {...style} {...rest}>
      {headline && (
        <Box mb={2}>
          <Heading size="h7" textAlign="center" style={{ fontWeight: 'bold' }} color={theme.colors.baseGrayDark}>
            {headline}
          </Heading>
        </Box>
      )}
      <Badges
        center
        badges={badges.map((b) => {
          const image = formatImage(b)
          const { name, links } = mapperWithFunction(b, BADGES_MAP)

          const link = links
            ? links[0].external
              ? {
                  as: 'a',
                  href: links[0].to,
                  target: '_blank',
                  rel: 'noreferrer noopener',
                }
              : {
                  as: Link,
                  target: '_self',
                  to: `/${links[0].year}/${links[0].month}/${links[0].slug}`,
                }
            : {}

          return { name, image, ...link }
        })}
      />
    </Box>
  )
}

export default BadgesWrapper

export const badges = graphql`
  fragment Badges on ContentfulLayoutBadges {
    id
    contentful_id
    name
    headline
    badges {
      locations
    }
    style {
      pb
      pt
    }
  }
`
